import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default (props) => {
  return props.caption ? (
    <div className="photo-caption">
      <Container>
        <Row>
          <Col>{props.caption}</Col>
        </Row>
      </Container>
    </div>
  ) : null
}
