import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PhotoCaption from '../../components/misc/photo-caption'
import { getSrc, GatsbyImage } from 'gatsby-plugin-image'

export default ({ parallaxSection }) => {
  return (
    <div
      className={`parallax-section ${parallaxSection.copy ? 'w-copy' : ''}`}
      style={{
        backgroundImage: parallaxSection.videoEmbedCode
          ? null
          : `url("${getSrc(parallaxSection.image)}")`,
      }}
    >
      {/* <GatsbyImage
        image={parallaxSection.image.gatsbyImageData}
        alt=""
        fit="cover"
        imgStyle={{
          objectPosition: 'top center',
          position: 'fixed',
          zIndex: -1,
          // position: 'sticky',
        }}
      /> */}
      <div className="overlay">
        {parallaxSection.videoEmbedCode ? (
          <div
            className="video-bg"
            style={{
              backgroundImage: `url("${getSrc(parallaxSection.image)}")`,
            }}
            dangerouslySetInnerHTML={{
              __html: parallaxSection.videoEmbedCode.videoEmbedCode,
            }}
          />
        ) : null}

        {parallaxSection.copy ? (
          <Container as="section">
            <Row>
              <Col
                md={{ span: 9, offset: 3 }}
                dangerouslySetInnerHTML={{
                  __html: parallaxSection.copy.childMarkdownRemark.html,
                }}
              ></Col>
            </Row>
          </Container>
        ) : null}
      </div>
      <PhotoCaption caption={parallaxSection.image.description} />
    </div>
  )
}
