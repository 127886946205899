import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout/layout'
import { Container, Row, Col } from 'react-bootstrap'
import AddThis from '../components/misc/addthis'
import CategoryNav from '../components/categories/nav'
import ParallaxSection from '../components/parallax-section/card'
import PhotoCaption from '../components/misc/photo-caption'
import { getSrc, GatsbyImage } from 'gatsby-plugin-image'
import LayoutContext from '../components/layout/context'
import CategoryPanel from '../components/categories/panel'
import GPTUnit from '../components/misc/gpt-unit'
import { transitionEvents } from '../components/layout/page-transition'
import gsap from 'gsap'
import PlaceholderCard from '../components/misc/placeholder-card'
import Footer from '../components/layout/footer'

class ArticleTemplate extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)
    const article = get(props, 'data.contentfulArticle')
    const category = article.category
    // HARD-CODING AD placement for articles
    const gptAdUnit = {
      slot: '/4052/oneyard',
      width: 150,
      height: 75,
      fullColor: true,
      targeting: {
        postID: article.contentful_id,
        category: category.slug,
        pg: props.location.pathname.replace(/^\//, ''),
      },
    }
    this.state = {
      article,
      category,
      gptAdUnit,
      entered: false,
    }
    this.listenHandler = this.listenHandler.bind(this)
  }

  componentWillMount() {
    this.context.set({
      // category: this.state.article.category,
      meta: this.state.article.metaTags,
      hidePresentingSponsor: true,
      category: this.state.category,
    })

    if (typeof window !== 'undefined') {
      global.window.addEventListener(transitionEvents.page, this.listenHandler)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      global.window.removeEventListener(
        transitionEvents.page,
        this.listenHandler
      )
    }
  }

  componentDidMount() {
    if (window && window.instgrm) {
      window.instgrm.Embeds.process()
    }
  }

  slideUp() {
    // animate slide up
    gsap.from(`#${this.state.article.slug}`, {
      y: '100vh',
      duration: 0.5,
      ease: 'power4.out',
    })
  }

  slideDown() {
    // animate slide down
    gsap.to(`#${this.state.article.slug}`, {
      y: '100vh',
      duration: 0.5,
      ease: 'power4.out',
    })
  }
  slideDownCategory() {
    // animate slide down
    gsap.to(`#placeholder-${this.state.category.slug}`, {
      y: '100vh',
      duration: 0.5,
      ease: 'power4.out',
      delay: 0.125,
    })
  }

  listenHandler(evt) {
    // make sure the event is happening for this location
    const {
      detail: {
        label,
        location: { pathname, state: locationState },
      },
    } = evt

    if (pathname === this.props.location.pathname) {
      // const evtType = evt.detail && evt.detail.label

      if (label === 'entering') {
        this.slideUp()
      } else if (label === 'entered') {
        this.setState({ entered: true })
      } else if (label === 'exiting') {
        this.slideDown()
      }
    } else if (pathname === `/categories/${this.state.category.slug}`) {
      if (label === 'enter') {
        this.setState({ entered: false })
      }
    } else {
      // to some other page! be sure to slide down category tab
      this.slideDownCategory()
    }
  }

  render() {
    const { article, category } = this.state
    return (
      <>
        {this.state.entered ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/categories/${article.category.slug}`)}
          >
            <CategoryPanel
              placeholder
              id={category.slug}
              category={category}
              hideCloseButton
            >
              <section
                style={{
                  backgroundColor: `rgb(241 235 217)`,
                  padding: '2rem',
                }}
              >
                <Container fluid="lg">
                  <Row className="align-items-center">
                    <Col
                      md={{ span: 6, order: 'first' }}
                      lg="5"
                      xs={{ order: 'last' }}
                    >
                      <PlaceholderCard hideImage />
                    </Col>
                    <Col
                      className="pb-5 pb-md-0"
                      md={{ span: 6 }}
                      lg={{ span: 5, offset: 2 }}
                    >
                      <PlaceholderCard hideContent />
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="articles-list-container">
                <Container fluid="lg">
                  <div className="articles-list">
                    {[1, 2, 3, 4, 5, 6].map((i) => (
                      <PlaceholderCard
                        key={`fpo-${i}`}
                        className="article-card"
                      />
                    ))}
                  </div>
                </Container>
              </section>
            </CategoryPanel>
          </div>
        ) : null}

        <div id={article.slug} className="article-panel">
          <button
            className="tab-close-button"
            onClick={() => {
              // this.context.toggleMap()
              navigate(`/categories/${article.category.slug}`)
            }}
            type="button"
          >
            <span>Close</span>
          </button>
          <div
            className={`article-header ${
              article.heroImage.description ? 'with-caption' : ''
            }`}
          >
            <GatsbyImage
              image={article.heroImage.gatsbyImageData}
              alt={article.heroImage.description || article.title}
              fit="cover"
              imgStyle={{
                objectPosition: 'center center',
              }}
            />
            <PhotoCaption caption={article.heroImage.description} />
          </div>
          <section
            className="pb-4"
            style={{
              backgroundColor: `#${article.category.colorPalette.backgroundColorLight}`,
            }}
          >
            <Container as="article" className="article-content">
              <Row>
                <Col md="3" className="text-md-center pt-2">
                  <Link to={`/categories/${article.category.slug}`}>
                    <img
                      src={article.category.icon.file.url}
                      alt={article.category.name}
                      className="article-category-icon"
                      // width={article.category.icon.file.details.image.width}
                      height={article.category.icon.file.details.image.height}
                    />
                  </Link>
                </Col>
                <Col md="9">
                  <h2
                    className="hu h1 mt-3 mt-md-0"
                    style={{
                      color: `#${article.category.colorPalette.primaryColor}`,
                    }}
                  >
                    {article.title}
                  </h2>

                  <div>
                    <GPTUnit
                      className="no-margin vertical"
                      {...this.state.gptAdUnit}
                    />
                  </div>
                  <div
                    className="article-dateline"
                    style={{
                      borderColor: `#${article.category.colorPalette.primaryColor}`,
                    }}
                  >
                    {article.author
                      ? [`${article.author.name} `, <span>&bull;</span>]
                      : ''}{' '}
                    {article.publishDate}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.intro.childMarkdownRemark.html,
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          {article.parallaxSections ? (
            <ParallaxSection parallaxSection={article.parallaxSections[0]} />
          ) : null}

          <div className="bg-white" style={{ position: 'relative' }}>
            <Container as="section">
              <Row>
                <Col md={{ span: 9, offset: 3 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.copy.childMarkdownRemark.html,
                    }}
                  />
                  <AddThis />
                </Col>
              </Row>
            </Container>
          </div>
          {article.parallaxSections && article.parallaxSections[1] ? (
            <ParallaxSection parallaxSection={article.parallaxSections[1]} />
          ) : null}
          {/* <CategoryNav active={article.category} /> */}
          <Footer />
        </div>
      </>
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      contentful_id
      title
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      category {
        name
        slug
        headline
        colorPalette {
          primaryColor
          secondaryColor
          backgroundColorLight
        }
        icon {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
      author {
        name
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH)
        file {
          url
        }
        description
      }
      heroImageBW {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      intro {
        childMarkdownRemark {
          html
        }
      }
      copy {
        childMarkdownRemark {
          html
        }
      }
      metaTags {
        title
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        url
        description {
          description
        }
      }
      parallaxSections {
        image {
          gatsbyImageData(width: 1200)
          description
        }
        videoEmbedCode {
          videoEmbedCode
        }
        copy {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
