import React from 'react'
// import { Container, Row, Col } from 'react-bootstrap'

export default (props) => {
  const { className } = props
  return (
    <div
      className={`card placeholder-card placeholder-glow ${className}`}
      aria-hidden="true"
    >
      {props.hideImage ? null : (
        <div
          className="placeholder"
          style={{ paddingTop: '56.25%', position: 'relative' }}
        ></div>
      )}
      {props.hideContent ? null : (
        <div className="card-body">
          <h5 className="card-title">
            <span className="placeholder col-6"></span>
          </h5>
          <p className="card-text">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
          <a
            href="#"
            tabIndex="-1"
            className="btn btn-primary disabled placeholder col-6"
          ></a>
        </div>
      )}
    </div>
  )
}
