import React from 'react'

class AddThis extends React.Component {
  componentDidMount() {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script')
      script.src =
        '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f357b5a6050c8df'
      this.instance.appendChild(script)
    }
  }

  render() {
    let { className } = this.props
    return (
      <>
        <div
          className={`addthis_inline_share_toolbox ${className || ''}`}
        ></div>
        <div ref={(el) => (this.instance = el)}></div>
      </>
    )
  }
}
export default AddThis
